var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"rounded":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('FilterP2PLending',{attrs:{"filters":_vm.filters,"showFilterBy":false},on:{"fetchData":_vm.setFilter}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"calculate-widths":"","loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"invoiceNo","server-items-length":_vm.listTotalEntry,"options":_vm.pagination,"footer-props":{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': _vm.$_item_per_page,
            disablePagination: _vm.isLoading,
            disableItemsPerPage: _vm.isLoading
          }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.dateFormat(item.createdAt)))])]}},{key:"item.totalAmount",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.formatCurrency(item.totalAmount)))]),_c('p',{staticClass:"mt-0 font-weight-bold"},[_vm._v(_vm._s(_vm.formatCurrency(item.totalNett)))])]}},{key:"item.dueDate",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.dateFormat(item.dueDate)))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.userAccess.canView)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.action(item, { pageName: 'detail-p2p-lending'})}}},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify ")])]}}],null,true)},[_c('span',[_vm._v("Lihat Detail")])]):_vm._e(),((item.status.toLowerCase() === 'menunggu persetujuan'
                || item.status.toLowerCase() === 'disetujui')
                && _vm.userAccess.canUpdate
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.action(item, { pageName: 'edit-p2p-lending'})}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.p2pLending.LIST_P2P_LENDING)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }