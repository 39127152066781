<template>
  <v-container fluid>
    <v-sheet rounded class="pa-4">
      <v-row>
        <v-col class="pa-0">
          <FilterP2PLending
            :filters="filters"
            :showFilterBy="false"
            @fetchData="setFilter"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            calculate-widths
            :loading="isLoading"
            :headers="displayedHeader"
            :items="items"
            item-key="invoiceNo"
            :server-items-length="listTotalEntry"
            :options.sync="pagination"
            :footer-props="{
              showCurrentPage: true,
              showFirstLastPage: true,
              'items-per-page-options': $_item_per_page,
              disablePagination: isLoading,
              disableItemsPerPage: isLoading
            }"
          >
            <template v-slot:[`item.createdAt`]={item}>
              <p class="mb-0">{{ dateFormat(item.createdAt) }}</p>
            </template>
            <template v-slot:[`item.totalAmount`]={item}>
              <p class="mb-0">{{ formatCurrency(item.totalAmount) }}</p>
              <p class="mt-0 font-weight-bold">{{ formatCurrency(item.totalNett) }}</p>
            </template>
            <template v-slot:[`item.dueDate`]={item}>
              <p class="mb-0">{{ dateFormat(item.dueDate) }}</p>
            </template>
            <template v-slot:[`item.actions`]={item}>
              <v-tooltip bottom v-if="userAccess.canView">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @click="action(item, { pageName: 'detail-p2p-lending'})"
                    class="mr-2"
                  >
                    mdi-magnify
                  </v-icon>
                </template>
                <span>Lihat Detail</span>
              </v-tooltip>
              <v-tooltip
                v-if="(item.status.toLowerCase() === 'menunggu persetujuan'
                  || item.status.toLowerCase() === 'disetujui')
                  && userAccess.canUpdate
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @click="action(item, { pageName: 'edit-p2p-lending'})"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$_strings.p2pLending.LIST_P2P_LENDING}}
                <span v-if="items.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import {
  dateFormat,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import dayjs from 'dayjs';
import FilterP2PLending from './FilterP2PLending';

export default {
  name: 'p2p-lending-list',
  components: {
    FilterP2PLending,
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.p2pLending.NO_P2P_LENDING,
          value: 'documentNo',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.p2pLending.TRANSPORTER_NAME,
          value: 'transporter',
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
        {
          text: this.$_strings.p2pLending.CREATED_AT,
          value: 'createdAt',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.p2pLending.TOTAL_BILL,
          value: 'totalAmount',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.p2pLending.DUE_DATE,
          value: 'dueDate',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.p2pLending.STATUS,
          value: 'status',
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
        {
          text: this.$_strings.p2pLending.ACTION,
          value: 'actions',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
      ],
      items: [],
      search: this.$route.query.search || '',
      filters: {
        dateFrom: this.$route.query.dateFrom || dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
        dateTo: this.$route.query.dateTo || dayjs().format('YYYY-MM-DD'),
      },
      listTotalEntry: 1,
      sort: 'createdAt,DESC',
      pagination: defaultPagination(),
    };
  },
  computed: {
    displayedHeader() {
      const { canUpdate, canView } = this.userAccess;
      return this.headers.filter((header) => {
        if ((!canUpdate || !canView) && header.value === 'actions') return;
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchData();
      },
      deep: true,
    },
    'filters.dateFrom': function setQueryDateFrom(newVal) {
      this.$router.replace({
        query: {
          dateFrom: newVal,
          dateTo: this.filters.dateTo,
        },
      });
    },
    'filters.dateTo': function setQueryDateFrom(newVal) {
      this.$router.replace({
        query: {
          dateFrom: this.filters.dateFrom,
          dateTo: newVal,
        },
      });
    },
  },
  methods: {
    dayjs,
    dateFormat,
    formatCurrency(number) {
      return (number && `Rp. ${number.toLocaleString('id-ID')}`) || '-';
    },
    setFilter() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchData();
    },
    setSearch() {
      const { search } = this.$route.query;
      if (search !== this.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.search,
          },
        });
      }
      this.filters.invoiceNo = this.search;
      this.setFilter();
    },
    action(item, { pageName }) {
      const {
        id,
        documentNo,
        totalAmount,
        totalNett,
        createdAt,
      } = item;
      const { page, itemsPerPage: size } = this.pagination;
      const { dateFrom, dateTo } = this.filters;
      this.$router.push({
        name: pageName,
        params: {
          id,
        },
        query: {
          documentNo,
          totalAmount,
          totalNett,
          dateFrom,
          dateTo,
          page: page - 1,
          size,
          createdAt,
          sort: this.sort,
        },
      });
    },
    async fetchData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: this.sort,
      };
      Object.keys(this.filters).forEach((filter) => {
        if (typeof this.filters[filter] === 'object'
        && this.filters[filter] !== null
        && this.filters[filter].length) {
          filters[filter] = `qin(${this.filters[filter].toString()})`;
          return;
        }
        if (this.filters[filter] && filter === 'invoiceNo') {
          filters[filter] = `qlike(${this.filters[filter]})`;
          return;
        }
        if (this.filters[filter]) {
          filters[filter] = this.filters[filter];
        }
      });
      this.sort = handleSortBy({ defaultValue: 'createdAt,DESC', sortBy, sortDesc });
      try {
        this.isLoading = true;
        const result = await this.$_services.p2pLending.fetchP2pLendingList(filters);
        this.items = result.data.contents;
        this.listTotalEntry = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
